<template>
  <div class="categories my-24">
    <client-only>
      <agile
        ref="categoryCarouselDesktop"
        :dots="false"
        :slides-to-show="7"
        :navButtons="false"
        class="hidden laptop:block"
      >
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/particulier">
            <img
              src="~assets/images/icons/particulier.png"
              class="mx-auto"
              alt="Particulier"
            />
            <br />
            {{ `Particulier`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/recreatie">
            <img
              src="~assets/images/icons/recreatie.png"
              class="mx-auto"
              alt="Recreatie"
            />
            <br />
            {{ `Recreatie`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/logistiek">
            <img
              src="~assets/images/icons/logistiek.png"
              class="mx-auto"
              alt="Logistiek"
            />
            <br />
            {{ `Logistiek`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/dakdekkers">
            <img
              src="~assets/images/icons/dakdekkers.png"
              class="mx-auto"
              alt="Dakdekkers"
            />
            <br />
            {{ `Dakdekkers`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/ballonvaart">
            <img
              src="~assets/images/icons/ballonvaart.png"
              class="mx-auto"
              alt="Ballonvaart"
            />
            <br />
            {{ `Ballonvaart`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/bedrijf">
            <img
              src="~assets/images/icons/bedrijf.png"
              class="mx-auto"
              alt="Bedrijf"
            />
            <br />
            {{ `Bedrijf`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/agrarisch">
            <img
              src="~assets/images/icons/agrarisch.png"
              class="mx-auto"
              alt="Agrarisch"
            />
            <br />
            {{ `Agrarisch`.toUpperCase() }}
          </nuxt-link>
        </div>
      </agile>
      <agile
        ref="categoryCarouselTablet"
        :dots="false"
        :slides-to-show="5"
        :navButtons="false"
        class="hidden tablet:block laptop:hidden"
      >
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/particulier">
            <img
              src="~assets/images/icons/particulier.png"
              class="mx-auto"
              alt="Particulier"
            />
            <br />
            {{ `Particulier`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/recreatie">
            <img
              src="~assets/images/icons/recreatie.png"
              class="mx-auto"
              alt="Recreatie"
            />
            <br />
            {{ `Recreatie`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/logistiek">
            <img
              src="~assets/images/icons/logistiek.png"
              class="mx-auto"
              alt="Logistiek"
            />
            <br />
            {{ `Logistiek`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/dakdekkers">
            <img
              src="~assets/images/icons/dakdekkers.png"
              class="mx-auto"
              alt="Dakdekkers"
            />
            <br />
            {{ `Dakdekkers`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/ballonvaart">
            <img
              src="~assets/images/icons/ballonvaart.png"
              class="mx-auto"
              alt="Ballonvaart"
            />
            <br />
            {{ `Ballonvaart`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/bedrijf">
            <img
              src="~assets/images/icons/bedrijf.png"
              class="mx-auto"
              alt="Bedrijf"
            />
            <br />
            {{ `Bedrijf`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/agrarisch">
            <img
              src="~assets/images/icons/agrarisch.png"
              class="mx-auto"
              alt="Agrarisch"
            />
            <br />
            {{ `Agrarisch`.toUpperCase() }}
          </nuxt-link>
        </div>
      </agile>
      <agile
        ref="categoryCarouselMobile"
        :dots="false"
        :slides-to-show="2"
        class="tablet:hidden px-10 category-carousel"
      >
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/particulier">
            <img
              src="~assets/images/icons/particulier.png"
              class="mx-auto"
              alt="Particulier"
            />
            <br />
            {{ `Particulier`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/recreatie">
            <img
              src="~assets/images/icons/recreatie.png"
              class="mx-auto"
              alt="Recreatie"
            />
            <br />
            {{ `Recreatie`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/logistiek">
            <img
              src="~assets/images/icons/logistiek.png"
              class="mx-auto"
              alt="Logistiek"
            />
            <br />
            {{ `Logistiek`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/dakdekkers">
            <img
              src="~assets/images/icons/dakdekkers.png"
              class="mx-auto"
              alt="Dakdekkers"
            />
            <br />
            {{ `Dakdekkers`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/ballonvaart">
            <img
              src="~assets/images/icons/ballonvaart.png"
              class="mx-auto"
              alt="Ballonvaart"
            />
            <br />
            {{ `Ballonvaart`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/bedrijf">
            <img
              src="~assets/images/icons/bedrijf.png"
              class="mx-auto"
              alt="Bedrijf"
            />
            <br />
            {{ `Bedrijf`.toUpperCase() }}
          </nuxt-link>
        </div>
        <div class="category text-center mt-auto">
          <nuxt-link to="/service/agrarisch">
            <img
              src="~assets/images/icons/agrarisch.png"
              class="mx-auto"
              alt="Agrarisch"
            />
            <br />
            {{ `Agrarisch`.toUpperCase() }}
          </nuxt-link>
        </div>

        <template slot="prevButton">
          <span v-html="'<'" class="text-3xl" />
        </template>
        <template slot="nextButton">
          <span v-html="'>'" class="text-3xl" />
        </template>
      </agile>
    </client-only>
  </div>
</template>

<script>
export default {
  name: 'Categories'
}
</script>
