<template>
  <footer>
    <section class="call-us bg-cyanMedium flex">
      <h6 class="text-2xl tablet:text-4xl text-white m-auto">
        {{ `Bel ons!`.toUpperCase() }}
        <span class="fa-stack mx-0 tablet:mx-4 text-xl text-white">
          <i class="fas fa-circle fa-stack-2x" />
          <i class="fas fa-phone-alt fa-stack-1x fa-inverse text-cyanMedium" />
        </span>
        <a
          :href="`tel:${footer.acf.telefoonnummer.replace(/ /g, '')}`"
          class="text-white hover:text-white75"
          >{{ footer.acf.telefoonnummer }}</a
        >
      </h6>
    </section>
    <section class="contact bg-cyanDark">
      <div class="container flex flex-wrap py-16 text-center tablet:text-left">
        <div
          class="w-full tablet:w-1/2 laptop:w-1/4 desktop:w-1/6 ml-auto pb-8 tablet:pb-0"
        >
          <h6 class="text-white">
            <strong>
              {{ `Locatie Oss`.toUpperCase() }}
            </strong>
          </h6>
          <br />
          <div v-html="footer.acf.locatie_oss"></div>
        </div>
        <div class="hidden laptop:block w-1/4 desktop:w-1/6 mx-auto"></div>
        <div class="w-full tablet:w-1/2 laptop:w-1/4 desktop:w-1/6 mr-auto">
          <h6 class="text-white">
            <strong>
              {{ `Locatie Zeeland`.toUpperCase() }}
            </strong>
          </h6>
          <br />
          <div v-html="footer.acf.locatie_zeeland"></div>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    footer() {
      return this.$store.getters.getFooter
    }
  }
}
</script>
