<template>
  <header class="bg-cyanDark text-white relative">
    <section
      class="top-header z-20 bg-cyanDark container flex flex-wrap relative"
    >
      <div
        class="w-full laptop:w-1/3 order-2 laptop:order-1 pl-8 laptop:pl-0 flex header-logo"
      >
        <nuxt-link class="my-auto home" to="/">
          <img src="~assets/images/logo.png" alt="Gasservice Willemsen logo" />
        </nuxt-link>
      </div>
      <div class="w-full laptop:w-2/3 order-1 laptop:order-2">
        <div class="flex flex-wrap">
          <div
            class="w-2/3 laptop:w-1/2 mx-auto tablet:ml-auto tablet:mr-0 header-location flex"
          >
            <span class="my-auto pr-4 text-lg">locatie</span>
            <a
              href="https://www.google.nl/maps/place/Galli%C3%ABrsweg+45,+5349+AT+Oss/@51.7584744,5.5556681,17z/data=!3m1!4b1!4m5!3m4!1s0x47c6fd067a119ee7:0x577f69d844d40270!8m2!3d51.7584744!4d5.5578621"
              target="_blank"
            >
              <div class="location inline-block bg-white75 flex relative">
                <span
                  class="inline-block text-cyanDark hover:text-cyanDark text-xl m-auto"
                >
                  {{ `Oss`.toUpperCase() }}
                </span>
              </div>
            </a>
            <a
              href="/contact"
            >
              <div class="location inline-block bg-white50 flex relative">
                <span
                  class="inline-block text-cyanDark hover:text-cyanDark text-xl m-auto"
                >
                  {{ `Zeeland`.toUpperCase() }}
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section
      class="header-menu z-30 w-1/3 laptop:w-full flex flex-wrap absolute"
    >
      <div class="container flex">
        <div
          class="hidden w-7/12 ml-auto laptop:flex header-menu-bg bg-blue relative"
        >
          <span class="w-1/5 flex before home-icon pl-4">
            <nuxt-link class="my-auto mx-auto home" to="/">
              <img src="~assets/images/home.png" alt="Home icon" />
            </nuxt-link>
          </span>
          <span class="w-1/5 my-auto text-center text-xl">
            <nuxt-link
              :to="localePath('about-us')"
              class="text-white hover:text-white"
            >
              {{ `Over ons`.toUpperCase() }}
            </nuxt-link>
          </span>
          <span class="w-1/5 my-auto text-center text-xl">
            <nuxt-link
              :to="localePath('service')"
              class="text-white hover:text-white"
            >
              {{ `Service`.toUpperCase() }}
            </nuxt-link>
          </span>
          <span class="w-1/5 my-auto text-center text-xl">
            <nuxt-link
              :to="localePath('products')"
              class="text-white hover:text-white"
            >
              {{ `Producten`.toUpperCase() }}
            </nuxt-link>
          </span>
          <span class="w-1/5 my-auto text-center text-xl">
            <nuxt-link
              :to="localePath('contact')"
              class="text-white hover:text-white"
            >
              {{ `Contact`.toUpperCase() }}
            </nuxt-link>
          </span>
        </div>
        <div
          class="laptop:hidden w-1/2 ml-auto flex header-menu-bg bg-blue relative"
        >
          <span class="hidden tablet:flex w-1/2 before home-icon pl-4">
            <nuxt-link class="my-auto mx-auto home" to="/">
              <img src="~assets/images/home.png" alt="Home icon" />
            </nuxt-link>
          </span>
          <span
            class="w-full tablet:w-1/2 flex before home-icon pl-2 tablet:pl-4"
          >
            <div
              :class="{ open: showMenu }"
              @click="changeMobilemenu"
              class="hamburger"
            >
              <span />
              <span />
              <span />
              <span />
            </div>
          </span>
        </div>
      </div>
    </section>
    <section class="header-phone z-30 w-full flex flex-wrap absolute">
      <div class="container flex">
        <div
          class="w-1/7 laptop:w-2/7 desktop:w-1/4 ml-auto flex header-phone-bg bg-cyanMedium relative"
        >
          <span class="before" />
          <a
            :href="`tel:${footer.acf.telefoonnummer.replace(/ /g, '')}`"
            class="flex text-white hover:text-white75"
          >
            <span
              class="fa-stack mx-auto tablet:ml-4 text-lg tablet:text-2xl text-white my-auto"
            >
              <i class="fas fa-circle fa-stack-2x" />
              <i
                class="fas fa-phone-alt fa-stack-1x fa-inverse text-cyanMedium"
              />
            </span>
          </a>
          <span
            class="hidden laptop:inline-block my-auto ml-auto text-2xl pr-7"
          >
            <a
              :href="`tel:${footer.acf.telefoonnummer.replace(/ /g, '')}`"
              class="text-white hover:text-white75"
            >
              <strong>{{ footer.acf.telefoonnummer }}</strong>
            </a>
          </span>
        </div>
      </div>
    </section>
    <section
      v-show-slide="showMenu"
      class="uncollapsed-menu z-10 bg-cyanDark absolute"
    >
      <div class="menu-content">
        <p class="text-2xl text-white">
          <nuxt-link
            :to="localePath('index')"
            class="text-white hover:text-white home"
          >
            {{ `Home`.toUpperCase() }}
          </nuxt-link>
          <br />
          <nuxt-link
            :to="localePath('about-us')"
            class="text-white hover:text-white"
          >
            {{ `Over ons`.toUpperCase() }}
          </nuxt-link>
          <br />
          <nuxt-link
            :to="localePath('service')"
            class="text-white hover:text-white"
          >
            {{ `Service`.toUpperCase() }}
          </nuxt-link>
          <br />
          <nuxt-link
            :to="localePath('products')"
            class="text-white hover:text-white"
          >
            {{ `Producten`.toUpperCase() }}
          </nuxt-link>
          <br />
          <nuxt-link
            :to="localePath('contact')"
            class="text-white hover:text-white"
          >
            {{ `Contact`.toUpperCase() }}
          </nuxt-link>
        </p>
      </div>
    </section>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    footer() {
      return this.$store.getters.getFooter
    }
  },
  watch: {
    $route() {
      this.showMenu = false
    }
  },
  methods: {
    changeMobilemenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>
