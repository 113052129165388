<template>
  <main id="products">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Gasservice Willemsen banner" />
    </section>
    <section class="content container flex content pt-12 pb-24">
      <div class="w-3/4 mx-auto">
        <div class="-mx-2">
          <div v-for="product in products" :key="product.id" class="p-2">
            <div
              @click="
                activeFaq = activeFaq === product.title ? '' : product.title
              "
              class="cursor-pointer flex flex-wrap border border-cyanLight p-4"
            >
              <div class="w-full tablet:w-1/3 laptop:w-1/4 pr-2 flex">
                <img
                  :src="product.media.medium_large"
                  :style="
                    `transition: .5s; ${
                      activeFaq === product.title
                        ? 'width: auto;height:250px'
                        : 'width: auto;height:50px'
                    }`
                  "
                  class="mx-auto"
                  alt="Product placeholder"
                />
              </div>
              <div class="w-full tablet:w-2/3 laptop:w-3/4 pl-2">
                <strong
                  v-html="product.title"
                  class="product-title my-auto text-2xl"
                ></strong>
                <i
                  v-if="activeFaq === product.title"
                  class="text-xl ml-2 fas fa-chevron-up"
                ></i>
                <i v-else class="text-xl ml-2 fas fa-chevron-down"></i>
                <p
                  v-show-slide="activeFaq === product.title"
                  v-html="product.content"
                  class="my-auto"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Products',
  async asyncData() {
    const [page, products] = await Promise.all([
      wordpress.get(`page/faq`),
      wordpress.get(`category/faq?per_page=999`)
    ])

    return {
      page: page.data,
      products: products.data,
      activeFaq: ''
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
