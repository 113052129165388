<template>
  <main id="homepage">
    <section class="banner">
      <client-only>
        <agile
          ref="serviceCarousel"
          :dots="false"
          :fade="true"
          :slides-to-show="1"
          :autoplay="true"
          :autoplaySpeed="5000"
          :navButtons="false"
        >
          <div :key="b.id" v-for="b in page.acf.banner">
            <img :src="b.url" alt="Gasservice Willemsen banner" />
          </div>
        </agile>
      </client-only>
    </section>
    <section class="alert bg-blue flex">
      <span class="text-white m-auto text-lg tablet:text-2xl text-center">
        <span class="hidden tablet:inline-block fa-stack text-xl">
          <i class="fas fa-circle fa-stack-2x" />
          <i class="fas fa-exclamation fa-stack-1x fa-inverse text-blue" />
        </span>

        {{ page.acf.alert.toUpperCase() }}
      </span>
    </section>
    <section class="subtitle bg-cyanDark tablet:flex">
      <span
        class="hidden tablet:inline-block text-white text-2xl laptop:text-4xl font-light m-auto"
      >
        <div
          v-if="page.acf.subtitel.length !== index + 1"
          :key="`${title.tekst}1`"
          v-for="(title, index) in page.acf.subtitel"
          class="inline"
        >
          {{ title.tekst.toUpperCase() }}
          <span class="text-blue">|</span>
        </div>
        <div
          v-if="page.acf.subtitel.length === index + 1"
          :key="`${title.tekst}1`"
          v-for="(title, index) in page.acf.subtitel"
          class="inline"
        >
          {{ title.tekst.toUpperCase() }}
        </div>
      </span>

      <client-only>
        <agile
          ref="serviceCarousel"
          :dots="false"
          :slides-to-show="1"
          :autoplay="true"
          :autoplaySpeed="1500"
          :navButtons="false"
          class="tablet:hidden py-4"
        >
          <div
            :key="`${title.tekst}2`"
            v-for="title in page.acf.subtitel"
            class="text-center"
          >
            <span class="text-white text-3xl font-light">
              {{ title.tekst.toUpperCase() }}
            </span>
          </div>
        </agile>
      </client-only>
    </section>
    <section class="content container">
      <div class="content-text flex my-8 laptop:my-20 px-8">
        <div
          v-html="page.acf.content_tekst"
          class="w-full laptop:w-3/4 mx-auto"
        />
      </div>

      <div class="logos my-12 tablet:my-20">
        <client-only>
          <agile
            ref="logoCarouselDesktop"
            :dots="false"
            :slides-to-show="5"
            :navButtons="false"
            class="hidden laptop:block -mx-4"
          >
            <div :key="`${merk.id}1`" v-for="merk in page.acf.merk_logos">
              <img :src="merk.url" class="p-4" alt="logo block" />
            </div>
          </agile>
          <agile
            ref="logoCarouselTablet"
            :dots="false"
            :autoplay="true"
            :slides-to-show="3"
            :navButtons="false"
            class="hidden tablet:block laptop:hidden -mx-4"
          >
            <div :key="`${merk.id}2`" v-for="merk in page.acf.merk_logos">
              <img :src="merk.url" class="p-4" alt="logo block" />
            </div>
          </agile>
          <agile
            ref="logoCarouselMobile"
            :dots="false"
            :autoplay="true"
            :slides-to-show="2"
            :navButtons="false"
            class="tablet:hidden px-8"
          >
            <div :key="`${merk.id}3`" v-for="merk in page.acf.merk_logos">
              <img :src="merk.url" alt="logo block" />
            </div>
          </agile>
        </client-only>
      </div>

      <div
        v-if="page.acf.aanbieding.aanbiedingactief"
        class="offer flex flex-wrap my-20"
      >
        <div
          v-html="page.acf.aanbieding.aanbiedingtekst"
          class="text w-full laptop:w-2/3 desktop:w-3/4 order-2 laptop:order-1 bg-cyanDark text-white p-12 desktop:p-16"
        />
        <div
          class="image w-full laptop:w-1/3 order-1 laptop:order-2 desktop:w-1/4 h-full"
        >
          <img
            :src="page.acf.aanbieding.aanbiedingafbeelding.sizes.medium_large"
            class="w-full"
            alt="Winteraanbieding"
          />
        </div>
      </div>

      <categories-component />

      <div class="faq flex flex-wrap tablet:flex-nowrap mt-24">
        <div class="image w-full tablet:w-1/3">
          <img
            src="~assets/images/fles-zelf-vullen.png"
            class="w-full h-full"
            alt="Fles zelf vullen"
          />
        </div>
        <div class="text w-full tablet:w-2/3 bg-cyanMedium text-center p-16">
          <img
            src="~assets/images/question-mark.png"
            class="mx-auto pb-6"
            alt="Vraagteken icoon"
          />
          <h4 class="text-white">
            Kan ik een fles zelf vullen of aan de pomp laten vullen?
          </h4>
        </div>
      </div>
      <div>
        <nuxt-link to="/faq">
          <div
            class="faq-bottom mb-24 bg-blue text-xl py-4 text-center text-white"
          >
            <strong>
              {{ `Veelgestelde vragen`.toUpperCase() }}
            </strong>
          </div>
        </nuxt-link>
      </div>
    </section>
  </main>
</template>

<script>
import categoriesComponent from '~/components/categories.vue'
import wordpress from '~/service/wordpress'

export default {
  name: 'Homepage',
  components: {
    categoriesComponent
  },
  async asyncData() {
    const page = await wordpress.get(`page/homepage`)

    return {
      page: page.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
