<template>
  <main id="contact">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Gasservice Willemsen banner" />
    </section>
    <section class="content container flex content pt-12 pb-24">
      <div class="w-3/4 flex flex-wrap mx-auto">
        <h3 class="pb-4 w-full">
          CONTACT INFO
        </h3>

        <div v-html="page.content" class="info w-full laptop:w-1/2"></div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress';

export default {
  name: 'Contact',
  data() {
    return {
      loading: false,
      message: '',
      form: {
        name: '',
        email: '',
        subject: '',
        message: ''
      },
    }
  },
  async asyncData() {
    const [page] = await Promise.all([wordpress.get(`page/contact`)])

    return {
      page: page.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {

    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },

    sendContactForm() {
      this.loading = true
      const params = new URLSearchParams()

      if (this.validateEmail(this.form.email)) {
        params.append('your-name', this.form.name)
        params.append('your-email', this.form.email)
        params.append('your-subject', this.form.subject)
        params.append('your-message', this.form.message)
        this.$axios
          .post(
            'https://backend.gasservicewillemsen.nl/cms/wp-json/contact-form-7/v1/contact-forms/5/feedback',
            params,
            { 'Content-Type': 'application/x-www-form-urlencoded' }
          )
          .then(() => {
            this.loading = false
            this.message =
              'Bedankt, wij nemen zo snel mogelijk contact met je op!'
            this.form = {
              name: '',
              email: '',
              subject: '',
              message: ''
            }
          })
          .catch(() => {
            this.loading = false
            this.message =
              'Iets ging er niet goed, probeer het later nog eens...'
          })
      } else {
        this.loading = false
        this.message = 'Ongeldig emailadres.'
      }
    }
  }
}
</script>
