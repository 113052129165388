<template>
  <main id="service">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Gasservice Willemsen banner" />
    </section>
    <section class="content container flex content pt-12 pb-24">
      <div class="w-3/4 mx-auto">
        <div v-for="service in page.acf.services" class="mb-12">
          <div id="particulier" class="section-title text-2xl mb-2">
            <img
              :src="service.icon.url"
              :alt="service.title"
              style="width: 25px;margin-right:5px;vertical-align: baseline"
              class="inline-block"
            />
            <strong v-html="service.title" class="inline-block"></strong>
          </div>
          <p v-html="service.text"></p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Service',
  async asyncData() {
    const page = await wordpress.get(`page/service`)

    return {
      page: page.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
