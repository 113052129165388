<template>
  <main id="service">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Gasservice Willemsen banner" />
    </section>
    <section class="content container flex content pt-12">
      <div v-html="page.content" class="w-3/4 mx-auto" />
    </section>

    <section class="categories container">
      <categories-component />
    </section>
  </main>
</template>

<script>
import categoriesComponent from '~/components/categories.vue'
import wordpress from '~/service/wordpress'

export default {
  name: 'Service',
  components: {
    categoriesComponent
  },
  async asyncData({ params }) {
    const page = await wordpress.get(`page/service/${params.slug}`)

    return {
      page: page.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
