<template>
  <main id="products">
    <section class="banner">
      <img :src="page.acf.banner.url" alt="Gasservice Willemsen banner" />
    </section>
    <section class="content container flex content pt-12 pb-24">
      <div class="w-3/4 mx-auto">
        <div class="-mx-2">
          <div v-for="product in products" :key="product.id" class="p-2">
            <div class="flex flex-wrap border border-cyanLight p-4">
              <div class="w-full tablet:w-1/3 pr-2">
                <img
                  :src="product.media.medium_large"
                  class="w-full"
                  alt="Product placeholder"
                />
              </div>
              <div class="w-full tablet:w-2/3 pl-2">
                <strong
                  v-html="product.title"
                  class="product-title my-auto text-2xl"
                ></strong>
                <p v-html="product.content" class="my-auto"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import wordpress from '~/service/wordpress'

export default {
  name: 'Products',
  async asyncData() {
    const [page, products] = await Promise.all([
      wordpress.get(`page/producten`),
      wordpress.get(`category/producten?per_page=999`)
    ])

    return {
      page: page.data,
      products: products.data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: this.page.title,
        page_path: this.$route.fullPath
      })
    }
  }
}
</script>
